import React from "react";
import { css } from "styled-components";

export const svgStyle = css`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

export const cloneChildren = (children, newProps) => {
    if (Array.isArray(children)) {
      return children.map(ch => (ch ? React.cloneElement(ch, newProps) : null));
    }
    return children ? React.cloneElement(children, newProps) : null;
  };