import styled from "styled-components";
import Modal from "sushiweb/Modal/Modal";
import "../../../styles/variables.scss";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  background: #f8f8fb;
  height: 100%;
  width:100%;
  position:fixed;
`;

export const SeparatorLine = styled.div`
  position: relative !important;
  border: 1px solid #f8f8fb;
  margin-top: ${props=> props.mt? props.mt : "16px"};
  margin-bottom: ${props=> props.mb? props.mb : "16px"};
  width: 100% !important;
`;

export const DottedLine = styled.div`
  border: 1px dashed #f8f8fb;
  margin-top: 20px;
  margin-bottom: 16px;
`;

export const Button = styled.button`
  background: #ef4f5f;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: none;
`;

export const WhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px 16px 16px 16px;
  border-top: 1px solid #f2f4f7;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.08);
`;

export const VerticalLine = styled.div`
  position: relative !important;
  border: 1px solid #f8f8fb;
  width: 0px !important;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px 0px 16px 0px;
  border: ${props => props.border ? props.border :"1px solid #F2F4F7"};
  box-shadow: ${props=> props.hideShadow? "" : "0px 4px 20px rgba(28, 28, 28, 0.04)"};
  border-radius: ${props=> props.smallRadius? "12px":"20px"};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 16px 0px 16px 0px;
`;

export const SushiModal = styled(Modal)`
  div:nth-child(2) {
    border-radius: 24px;
    box-shadow: 0px -4px 6px rgba(28, 28, 28, 0.06);
    width: 25vw;
    @media (max-width: 992px) {
      width: 80vw;
    }
  }
`;

export const BottomModal = styled(Modal)`
  >div:nth-child(2) {
    position: absolute;
    bottom: 0;
    width: 100vw;
    background: #f8f8fb;
    border-radius: 20px 20px 0px 0px;
    max-height: 60vh;
    height: fit-content;
    overflow-y: auto;
  }
  .close {
    position: absolute;
    cursor: pointer;
    font-size: 16px;
    color: #696969;
    right: 20px;
  }
`;

export const SpinLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100vw;
`;
export const RightModal = styled(Modal)`
  div:nth-child(2) {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 50vw;
    background: #f8f8fb;
    padding: 10px;
  }
`;
