import React, { useState, useEffect } from "react";
import Button from "sushiweb/Button";
import styles from "./Login.module.scss";
import OtpInput from "react-otp-input";
import Header from "../Header/Header";
import Spinner from "sushiweb/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorDisp } from "./styles.login";
import useAuthStore from "../Auth/useAuthStore";
import useVendorStore from "../HomePage/useVendorStore";
import Modal from "sushiweb/Modal";
import LandingPage from "../LandingPage/LandingPage";

const OtpVerify = (props) => {
  const { timer, setTimer } = props;
  const [resendOtpButton, setResendOtpButton] = useState(false);
  const [disableOtpVerify, setDisableOtpVerify] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sellerDetails = useVendorStore((state) => state.sellerDetails);
  const getSellerDetails = useVendorStore((state) => state.getSellerDetails);
  const redirectPath = searchParams.get("redirect") || "/";
 
  useEffect(() => {
    if (props.loginDetails.validateOtp.done === true) {
      const newLoginFlowEnabeled =  window.localStorage.getItem("is_new_seller_hub_login_flow_enabled")
      if (newLoginFlowEnabeled === "true") {
        window.localStorage.setItem("phone_number","")
      } else {
        window.localStorage.setItem("phone_number", props.phoneNumber)
      }
      const phoneNumber = window.localStorage.getItem("phone_number")
      getSellerDetails({
        phone_number: phoneNumber,
      })
    }
  }, [props.loginDetails.validateOtp])

  useEffect(() => {
    if (props.otp?.length === 6) {
      setDisableOtpVerify(false);
    } else {
      setDisableOtpVerify(true);
    }
  }, [props.otp]);

  useEffect(() => {
    if (timer > 0) {
      const t =
        timer > 0 &&
        setTimeout(() => {
          setTimer(timer - 1);
        }, 1000);

      return () => {
        if (t) {
          clearTimeout(t);
        }
      };
    } else {
      setResendOtpButton(true);
    }
  }, [timer]);

  useEffect(() => {
    if (props.loginDetails.validateOtp.begin === true) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }

    if (props.loginDetails.validateOtp.fail === true) {
      setShowError(true);
    }
  }, [props.loginDetails.validateOtp]);

  useEffect(() => {
    if (props.loginDetails.validateOtp.done === true) {
      let sellerDetail = sellerDetails?.seller_outlet_details[0]
      if (sellerDetail?.tnc_accepted) {
        navigate(redirectPath);
      } else {
        navigate(({pathname: "/tnc"}))
      }
    }
  }, [sellerDetails])

  return (
    <React.Fragment>
      <Header toggleLoginModal={props.toggleOtpModal}/>
      <Modal
          visible={props.showOtpModal}
          isCentered={props.showOtpModal}
          onClose={props.toggleOtpModal}
          className={styles.modalRadius}
        >
      <div className={`d-flex flex-column align-items-center ${styles.modalContent}`}>
        {/* <div className={`d-flex w-700 fs-21 wd-90 py-3`}>
          <div className={`${styles.Heading} d-flex fw-700 fs-21`}>
            {" "}
            Enter Verification Code{" "}
          </div>
        </div> */}

        {/* <div className={`fs-13 w-400 wd-90 pb-5 text-gray-cool`}>
          {" "}
          6 digit OTP has been sent to {props.isEmailUsed?`${props.userInput}`: `+91${props.userInput}`}
        </div> */}
        <div className={`d-flex flex-column justify-content-center wd-90 p-4`}>
          <img
            src={props.homePageData?.login_phone_number_image?.media_url}
            height="160px"
            width="160px"
          />

          <div className="fs-30 fw-600 mt-4">Enter verification code</div>
          <div className="fs-16 fw-400 text-gray-600 mt-2 mb-4">6 digit OTP has been sent to {props.isEmailUsed ? "":"+91"} {props.userInput}</div>
          <OtpInput
            inputType="number"
            containerStyle={`${styles.otpInputField} mb-3`}
            value={props.otp}
            onChange={(val) => {
              props.setOtp(val);
            }}
            numInputs={6}
            renderSeperator={"-"}
            renderInput={(a) => <input {...a} />}
          />
          {showError ? (
            <errorDisp className="fs-12 text-red-700">
              Wrong OTP Entered
            </errorDisp>
          ) : (
            <></>
          )}
          {timer <= 9 ? (
            <div className={`fs-17 w-600 fw-400 mt-4`}> 00:0{timer}</div>
          ) : (
            <div className={`fs-17 w-600 fw-400 mt-4`}> 00:{timer}</div>
          )}

          <div className="fs-13 fw-400 text-gray-800 mt-1">
            Didn’t receive the code?
            <button
              disabled={!resendOtpButton}
              onClick={props.handleResendOtp}
              className={
                !resendOtpButton
                  ? `textButton fw-400`
                  : `textButton fw-400 text-red-500`
              }
            >
              Resend now
            </button>
          </div>

          <Button
            btnColor="red"
            size="compressed"
            className={"verifyOtpButton"}
            onClick={props.handleOTPSubmit}
            disabled={disableOtpVerify}
          >
            <span className="fs-15 fw-500">Verify</span>
          </Button>
        </div>
      </div>
     
      {showLoader ? (
        <div className="d-flex wd-100 mt-6 align-items-center justify-content-center">
          <Spinner size="large" color="#EF4F5F" />
        </div>
      ) : (
        <></>
      )}
      </Modal>
      <LandingPage />
    </React.Fragment>
  );
};

export default OtpVerify;
