export const PROFILE_INPUT_BORDER = "#cfcfcf";


export const STATUS_BEGIN = "BEGIN"
export const STATUS_DONE = "DONE"
export const STATUS_FAIL = "FAIL"
export const STATUS_INIT = "INIT"

export const PO_STATUS_APPROVED = "APPROVED"
export const PO_STATUS_COMPLETED = "COMPLETED"

export const GRN_STATUS_APPROVED = "APPROVED"
export const GRN_STATUS_COMPLETED = "COMPLETED"

export const SELLER_STATUS_APPROVED = "APPROVED"
export const SELLER_STATUS_NON_APPROVED = "NON_APPROVED"

export const PAYMENT_STATUS_PENDING = "PENDING"

export const PO_ORDER_TAG = "DIRECT_DELIVERY"

export const ORDER_STATUS_DISPATCHED = "DISPATCHED"

export const SELLER_ACTION_STATUS_PENDING = "PENDING"
export const SELLER_ACTION_STATUS_COMPLETED = "COMPLETED"
export const SOURCE_DD = "dd"
export const SOURCE_SELLER_HUB = "SELLER_HUB"

export const PO_ORDER_TAG_DIRECT_DELIVERY = "DIRECT_DELIVERY"
export const PO_ORDER_TAG_MANDI_DD = "MANDI_DD"

export const REUPLOAD_VENDOR_INVOICE_STATUS_COMPLETED = "COMPLETED"

export const OSVERSION_WEB = "WEB";