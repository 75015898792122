export default {
  z50: "#FFF6F7",
  z100: "#FFEDEF",
  z200: "#FFDBE0",
  z300: "#FFB1C0",
  z400: "#FF7E8B",
  z500: "#EF4F5F",
  z_red: "#E23744",
  z600: "#E03546",
  z700: "#BF2938",
  z800: "#7D1B23",
  z900: "#4F191E",
  rgb: {
    z100: "255,237,239",
    z200: "255,219,224",
    z300: "255,177,193",
    z400: "255,126,139",
    z500: "239,79,95",
    z600: "224,53,70",
    z700: "191,41,56",
    z800: "125,27,35",
    z900: "79,25,30"
  }
};
