import React, { useEffect, useRef, useState } from "react";
import Modal from "sushiweb/Modal";
import styles from "./landingPageStyles.module.scss";
import { Formik, Field, Form, useFormikContext } from "formik";
import Button from "sushiweb/Button";
import useCommonStore from "../Common/useCommonStore";
import useAuthStore from "../Auth/useAuthStore";
import { CategoryModal } from "./CategoryModal";
import styled from "styled-components";
import * as Yup from 'yup';
import { isMobile } from "helpers/helper";
import { WhiteWrapper } from "../Common/styles.common";

const WhiteButton = styled.button`
  background: white;
  height: 56px;
  border-radius: 12px;
  border: 1px dashed #E6E9EF;
  box-shadow: 0px 4px 20px rgba(28, 28, 28, 0.04);
  margin-bottom: 10px;
`;

const RegristrationForm = ({ showContactUsModal, onToggleContactModal, categories, setShowSuccessModal}) => {

  const { uploadLogoutStateDocument, resetUploadedDocument} = useCommonStore();
  const uploadedDocumentUrl = useCommonStore(
    (state) => state.uploadedDocumentUrl
  );
 
  const { sellerOnboarding } = useAuthStore();
  const [sellerType, setSellerType] = useState("");
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [categoryInput, setCategoryInput] = useState([]);
  const isMweb = isMobile(992);

  const onFormSubmit = (values) => {
    const updatedResponse = {
      ...values,
      seller_catalogue_url: uploadedDocumentUrl,
      seller_type: sellerType,
      category_ids: categoryInput,
    };
    sellerOnboarding(updatedResponse, handleFormSuccessCallback);
  };

  const handleFormSuccessCallback = () => {
    onToggleContactModal();
    resetUploadedDocument();
    setShowSuccessModal(true);
  }

  const handleUploadDoc = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };
  const handleFileSelect = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      console.error("No file selected");
      return;
    }

    const file = files[0];
    const formData = new FormData();

    formData.append("fileType", "seller_onboarding_request_catalog");
    formData.append("source", "SELLER_HUB");

    const fileBlob = new Blob([file], { type: file.type });

    formData.append("file", fileBlob, file.name);

    uploadLogoutStateDocument(formData);
  };

  const fileInputRef = useRef(null);

  const categoryModalClosehandler = () => {
    setShowCategoryModal(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    
    phone_number: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone number is required'),
  
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  
    seller_type: Yup.string().required('Seller type is required'),
  
    brand_names: Yup.string().required('Brand names are required'),
  
    category_ids: Yup.array()
      .of(
        Yup.number()
          .integer('Category ID must be an integer')
          .min(1, 'Category ID must be at least 1')
      )
      .min(1, 'Please select at least one category')
      .required('Category IDs are required'),

    horeca_clients_count: Yup.number()
      .min(0, 'Number of horeca clients cannot be negative')
      .max(Number.MAX_SAFE_INTEGER, 'Please enter a valid number of horeca clients') // Prevent overflow
      .integer('Number of horeca clients must be an integer')
      .required('Horeca clients count is required'),
  
    seller_catalogue_url: Yup.string()
      .required('Seller catalogue URL is required'),
  
    active_cities: Yup.string().required('Please enter at least one city'),
  });

  const formikRef = useRef(null);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("seller_type", sellerType);
    }
  }, [sellerType]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("seller_catalogue_url", uploadedDocumentUrl);
    }
  }, [uploadedDocumentUrl]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("category_ids", categoryInput);
    }
  }, [categoryInput]);
   
  return (
    <>
      <CategoryModal 
        isModalOpen={showCategoryModal}
        categoryData={categories}
        modalClosehandler={categoryModalClosehandler}
        setCategoryInput={setCategoryInput}
        selectedCategoryIds={categoryInput}
      />
      <Modal
        visible={showContactUsModal}
        isCentered={true}
        onClose={() => {
          onToggleContactModal();
          resetUploadedDocument();
        }}
        showCloseIcon={false}
        className={styles.modalRadius}
      >
        <div className={`mt-3 ${styles.modalContent}`}>
        <div>
          <div
            style = {{borderBottom: "1px solid #F2F4F7"}}
            className={`${isMweb ? 'fs-24' : 'fs-30'} w-800 d-flex justify-content-between align-items-center ${isMweb ? `pt-4 ${styles.px24} pb-2` : styles.pd24}  ${styles.topHeaderBgWhite}`}
          >
            {"Tell us about your brand"}
            <img
              onClick={onToggleContactModal}
              src={'logos/cross.svg'}
              alt="cross icon"
              className={`${isMweb ? styles.img22 : styles.img32} pointer`}
            />
          </div>
        </div>
        <div style={isMweb ? {overflowY: "scroll", maxHeight: "80vh"}:{}}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: "",
            phone_number: "",
            email: "",
            seller_type: "",
            brand_names: "",
            category_ids: [],
            horeca_clients_count: 0,
            seller_catalogue_url: "",
            active_cities: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            onFormSubmit(values);
          }}
        >
          {({ values, errors, touched}) => (
            <Form
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                padding: "24px",
              }}
            >
              <label>
              <span className="fs-16 fw-600">Your details</span>
                <Field
                  name="name"
                  placeholder="Your name"
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "15px",
                  }}
                />
                {errors.name && touched.name && (
                  <div style={{ color: "red" }}>{errors.name}</div>
                )}
                <Field
                  name="phone_number"
                  placeholder="Phone number"
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "20px",
                  }}
                />
                {errors.phone_number && touched.phone_number && (
                  <div style={{ color: "red" }}>{errors.phone_number}</div>
                )}
                <Field
                  name="email"
                  placeholder="Email ID"
                  className="w-full p-[16px] border rounded-lg mt-[10px]"
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "20px",
                  }}
                />
                {errors.email && touched.email && (
                  <div style={{ color: "red" }}>{errors.email}</div>
                )}
                </label>
              <label>
                <span className="fs-16 fw-600">Brand details</span>
    
                <div style={{ display: "flex", flexDirection: "row" , marginTop: "16px", marginBottom: "12px"}}>
                  <div className="d-flex align-items-center pointer" onClick={()=>setSellerType("MANUFACTURER")}>
                    <img src={sellerType === "MANUFACTURER" ? "/logos/radio-active.svg" : "/logos/radio-circle.svg"} height="24px" width="24px"/>
                    <span className="fs-16 fw-400 ml-3">Manufacturer</span>
                  </div>

                  <div className="d-flex align-items-center pointer" onClick={()=>setSellerType("DISTRIBUTOR")}>
                    <img className="ml-5" src={sellerType === "DISTRIBUTOR" ? "/logos/radio-active.svg" : "/logos/radio-circle.svg"} height="24px" width="24px"/>
                    <span className="fs-16 fw-400 ml-3">Distributor</span>
                  </div>
                </div>

                {errors.seller_type && touched.seller_type && (
                  <div style={{ color: 'red' }}>{errors.seller_type}</div>
                )}

                <Field
                  name="brand_names"
                  placeholder="Enter brand name(s)"
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "10px",
                  }}
                />
                {errors.brand_names && touched.brand_names && (
                  <div style={{ color: "red" }}>{errors.brand_names}</div>
                )}
                <div
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "20px",
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCategoryModal(true)}
                >
                  <span 
                    style={{color:categoryInput.length === 0 ? "#95959D":"#1C1C1C", fontWeight: "400"}}>
                      {categoryInput.length === 0 ? "Select brand category" : `${categoryInput.length} ${categoryInput.length === 1 ? "category" : "categories"} added`}
                    </span>
                </div>

                {errors.category_ids && touched.category_ids && (
                  <div style={{ color: 'red' }}>{errors.category_ids}</div>
                )}

                <Field
                  name="active_cities"
                  placeholder="Enter active cities"
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "20px",
                  }}
                />
                {errors.active_cities && touched.active_cities && (
                  <div style={{ color: "red" }}>{errors.active_cities}</div>
                )}

                <div style={{fontSize:"16px", fontWeight:"600", marginTop: "20px"}}>How many HoReCa clients do you have?</div>

                <Field
                  name="horeca_clients_count"
                  placeholder="How many"
                  type="number"
                  style={{
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #E6E9EF",
                    borderRadius: "12px",
                    marginTop: "16px",
                  }}
                  min="1"  // Prevents negative numbers via arrow buttons
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "e" || e.key === "E") {
                      e.preventDefault();  // Prevents typing '-', 'e', or 'E'
                    }
                  }}
                  value={values.horeca_clients_count || ""} // Ensures placeholder is shown on empty state
                />

                {errors.horeca_clients_count && touched.horeca_clients_count && (
                  <div style={{ color: 'red' }}>{errors.horeca_clients_count}</div>
                )}

              </label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="fs-16 fw-600">Upload your catalogue</span>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#95959D",
                    fontWeight: 400,
                    marginTop: "8px",
                    marginBottom: "16px",
                  }}
                >
                  CSV/Excel/PDF file (max 5 MB) that contains your product
                  catalogue
                </div>
                <WhiteButton type="button" onClick={handleUploadDoc} className="pointer" style={{marginBottom: "60px"}} disabled={uploadedDocumentUrl}>
                  {uploadedDocumentUrl ?
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="text-green">File uploaded!</span>
                  </div>:
                  <div className="d-flex align-items-center justify-content-center">
                  
                  <img
                    src={"/logos/upload.svg"}
                    height="16px"
                    width="16px"
                  />
                  <span className="ml-3">Upload</span>
                  </div>
                  }
                </WhiteButton>
                <input
                  name="seller_catalogue_url"
                  type="file"
                  ref={fileInputRef}
                  accept="application/pdf,image/*,.csv"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
              </div>

              {errors.seller_catalogue_url && touched.seller_catalogue_url && (
                <div style={{ color: 'red' }}>{errors.seller_catalogue_url}</div>
              )}

              <WhiteWrapper style={{position: "absolute", bottom: "0px", width: "100%", left:"0px", borderBottomRightRadius: isMweb ? "" : "24px", borderBottomLeftRadius: isMweb ? "" : "24px"}}>
                <Button
                  type="submit"
                  className="pointer"
                  disabled={!values.name || !values.email || !values.phone_number || !sellerType || !values.brand_names || categoryInput?.length === 0
                    || values.horeca_clients_count === 0 || !uploadedDocumentUrl || !values.active_cities}
                >
                  Submit
                </Button>
              </WhiteWrapper>
            </Form>
          )}
        </Formik>
        </div>
        </div>
      </Modal>
      
    </>
  );
};

export default RegristrationForm;
