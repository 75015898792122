export default {
  z50: "#FCFCFC",
  z95: "#F8F8F8",
  z100: "#F8F8F8",
  z200: "#E8E8E8",
  z300: "#CFCFCF",
  z400: "#B5B5B5",
  z500: "#9C9C9C",
  z600: "#828282",
  z700: "#696969",
  z800: "#4F4F4F",
  z900: "#363636"
};
