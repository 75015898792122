import React, { useState } from "react";
import "../../../styles/buttons.scss";
import useAuthStore from "../Auth/useAuthStore";
import OtpVerify from "./OtpVerify";
import PhoneNumberInput from "./PhoneNumberInput";
import UserInput from "./UserInput";
import { strings } from "utils/constants/strings";

const Login = () => {
  const { sendOtp, validateOtp, loginDetails, error } = useAuthStore();

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userInput,setUserInput] = useState("")
  const [timer, setTimer] = useState(30);
  const [isValidationsFailed,setIsValidationsFailed] = useState(false)
  const [isEmailUsed,setIsEmailUsed] = useState(false)
  const [showOtpModal,setShowOtpModal]=useState(false)
  const homePageData = useAuthStore((state) => state.sellerHubData);

  const handlePhoneNumberSubmit = () => {
    sendOtp({ phone_no: phoneNumber });
  };

  const handleOTPSubmit = () => {
    if (isEmailUsed){
      validateOtp({ email: userInput, otp: otp });
    }else{
      validateOtp({ phone_no: userInput, otp: otp });
    }
  };

  const handleResendOtp = () => {
    if (isEmailUsed){
      sendOtp({ email: userInput});
    }else{
      sendOtp({ phone_no: userInput});
    }
    setTimer(30);
  };

  const handleUserInputSubmit = () => {
   setIsValidationsFailed(false)
   if (strings.loginEmailRegex.test(userInput)){
    setIsEmailUsed(true)
    sendOtp({ email: userInput});
    setShowOtpModal(true)
   }
   else if (strings.loginPhoneNumberRegex.test(userInput) && userInput.length == 10){
    setPhoneNumber(userInput)
    sendOtp({ phone_no: userInput});
    setShowOtpModal(true)
   }
   else{
   setIsValidationsFailed(true)
   }
  };
  const toggleOtpModal=()=>{
    setShowOtpModal((prev)=>!prev)
    if (!showOtpModal){
      setIsOtpSent(false)
      setUserInput("")
    }
  }
  return (
    <React.Fragment>
      {!isOtpSent ? (
        <UserInput
          userInput={userInput}
          phoneNumber ={phoneNumber}
          setUserInput={setUserInput}
          handleUserInputSubmit={handleUserInputSubmit}
          loginDetails={loginDetails}
          setIsOtpSent={setIsOtpSent}
          isValidationsFailed ={isValidationsFailed}
          error={error}
          homePageData={homePageData}
        />
      ) : (
        <OtpVerify
          userInput={userInput}
          phoneNumber ={phoneNumber}
          isEmailUsed ={isEmailUsed}
          otp={otp}
          setOtp={setOtp}
          handleResendOtp={handleResendOtp}
          timer={timer}
          setTimer={setTimer}
          handleOTPSubmit={handleOTPSubmit}
          loginDetails={loginDetails}
          error={error}
          showOtpModal={showOtpModal}
          toggleOtpModal={toggleOtpModal}
          homePageData={homePageData}
        />
      )}
    </React.Fragment>
  );
};

export default Login;
