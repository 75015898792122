import axios from "axios";
import { axiosInterceptor } from "./interceptors";
import { isMobile } from "helpers/helper";

axios.defaults.withCredentials = true;

let apiConfig;
axiosInterceptor();

const prepareResourcePath = (resource = "/", resourceConfig = {}) => {
  let processedRes = resource;
  Object.keys(resourceConfig).forEach((key) => {
    processedRes = processedRes.replace(`:${key}`, resourceConfig[key]);
  });

  const resPath = processedRes;
  return resPath;
};

const addQueryParamsToReq = (resourceURL = "", query = {}) => {
  if (Object.keys(query).length < 1) return resourceURL;
  const queryString = Object.keys(query)
    .reduce((accumulator, currentValue) => {
      accumulator.push(
        `${currentValue}=${encodeURIComponent(query[currentValue])}`
      );
      return accumulator;
    }, [])
    .join("&");

  return `${resourceURL}?${queryString}`;
};

const setNetworkConfig = (_config) => {
  if (!apiConfig) {
    apiConfig = _config;
  } else {
    throw new Error("calling setNetworkConfig multiple times is not permitted");
  }
};

const request = (endPoint, method, options, baseURL, body = {}) => {
  let defaultOptions = {
    query: {},
    params: {},
    baseUrl: apiConfig[baseURL],
    headers: {"AppType": isMobile(992) ? "mweb" : "web"},
  };
  options = { ...defaultOptions, ...options };
  const resourcePath = prepareResourcePath(endPoint, options.params || {});
  const resourcePathWithQuery = addQueryParamsToReq(
    resourcePath,
    options.query
  );
  const resourceHeaders = {
    ...options.headers,
  };
  return axios({
    baseURL: options.baseUrl,
    url: resourcePathWithQuery,
    method: method,
    data: body,
    headers: resourceHeaders,
  });
};

// api call
const api = {
  get: (baseURL, endPoint, options) => {
    return request(endPoint, "GET", options, baseURL);
  },
  post: (baseURL, endPoint, body = {}, options) => {
    return request(endPoint, "POST", options, baseURL, body);
  },
  delete: (baseURL, endPoint, options) => {
    return request(endPoint, "DELETE", options, baseURL);
  },
  put: (baseURL, endPoint, body = {}, options) => {
    return request(endPoint, "PUT", options, baseURL, body);
  },
};

export { api, setNetworkConfig };
