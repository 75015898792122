import styles from "./landingPageStyles.module.scss";
import { isMobile } from "helpers/helper";
import { useEffect, useRef, useState } from "react";
import { set } from "lodash";
import Modal from "sushiweb/Modal";
import Checkbox from "components/shared/InputTypes/Checkbox";
import Input from "components/shared/InputTypes/Input";
import {Button, WhiteWrapper} from "../Common/styles.common";



export const CategoryModal = ({isModalOpen, modalClosehandler, categoryData, setCategoryInput, selectedCategoryIds}) => {

    const isMweb = isMobile(992);
    const scrollRef = useRef();
    const [showScrollStateModal, setShowScrollStateModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedIds, setSelectedIds] = useState(selectedCategoryIds);

    const handleScroll = (e) => {
        if (e.target.scrollTop > 5 && modalData.Searchable) {
          setShowScrollStateModal(true);
        } else {
          setShowScrollStateModal(false);
        }
      };
    
    const dataFilterBySearch = () => {
        setModalData({
            ...modalData,
            Data: categoryData?.filter((i) =>
            i?.name?.toLowerCase().includes(modalData?.FilterSearch?.toLowerCase()),
            ),
        });
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelected) =>
          prevSelected.includes(id)
            ? prevSelected.filter((item) => item !== id) // Remove if unchecked
            : [...prevSelected, id] // Add if checked
        );
      };

    useEffect(() => {
        if  (isModalOpen) {
            setModalData({
                ...modalData,
                Data: categoryData,
                Searchable: true,
                FilterSearch: '',
            });
        }
        if (isModalOpen && scrollRef?.current) {
          scrollRef.current.scrollTo(0, 0);
        }
      }, [isModalOpen]);
      

    useEffect(() => {
        dataFilterBySearch();
      }, [modalData.FilterSearch]);

    return (
        <Modal
            visible={isModalOpen}
            onClose={modalClosehandler}
            isCentered
            className={styles.modalRadius}
            zIndex={22}
        >
        <div className={styles.modalContent} style={{background:"#F4F6FB"}}>
          <div>
            <div
              className={`${isMweb ? 'fs-24' : 'fs-30'} w-800 d-flex justify-content-between align-items-center ${styles.pd24}  ${showScrollStateModal ? "" : ''}`}
            >
              {"Select a category"}
              <img
                onClick={modalClosehandler}
                src={'logos/cross.svg'}
                alt="cross icon"
                className={`${isMweb ? styles.img22 : styles.img32} pointer`}
              />
            </div>
          </div>
          {modalData.Searchable ? (
            <div
              className={`${styles.px24} ${styles.paddingBottom0} relative ${showScrollStateModal ? styles.boxShadowTop : ''}`}
            >
              
              <Input
                className={`${styles.searchableInput}`}
                label={"Search a category"}
                hideLabel
                value={modalData.FilterSearch}
                onChange={(val) => {
                  setModalData({
                    ...modalData,
                    FilterSearch: val,
                  });
                }}
                leftIcon={<img
                    className={`${styles.img16} `}
                    src="/logos/search-icon.svg"
                    alt="search thick image"
                  />}
              />
            </div>
          ) : (
            <></>
          )}
          {modalData?.Data?.length ? (
            <div
              ref={scrollRef}
              onScroll={handleScroll}
              className={`${modalData.Searchable ? styles.dataBox : styles.dataBoxNonSearch} ${styles.px24} ${showScrollStateModal ? styles.borderRadius0 : ''}`}
            >
              {modalData?.Data?.map((item, index) => {
                return (
                  <div
                    key={item.Id}
                    className={`d-flex justify-content-between align-items-center ${styles.padding24pxTopBottom} ${styles.borderDotted} ${index === modalData.Data.length - 1 ? styles.marginBottom65px : ''}`}
                  >
                    <div
                      className={`${isMweb ? 'fs-15' : 'fs-18'} w-400  ${item.IsDisabled ? styles.greyColor : ''}`}
                    >
                      {item.name}
                    </div>
                    <div>
                      <Checkbox
                        className={`${styles.checkBoxFilter} ${item.IsDisabled ? styles.checkBoxDisabled : ''}`}
                        disabled={item.IsDisabled}
                        checked={selectedIds.includes(item.id)}
                        value="value"
                        onChange={()=> handleCheckboxChange(item.id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={`${modalData.Searchable ? styles.dataBox : styles.dataBoxNonSearch} mt-1 ${styles.px24} d-flex flex-column align-items-center justify-content-start ${styles.noContentDataBox}`}
            >
              {/* <Image
                alt={'no result image'}
                classname={`${styles.img200px} mt-4`}
                source={filterNoSearchResult.Image}
              />
              <div className={`fs-18 w-800 mt-3 ${styles.indigoColor}`}>
                {filterNoSearchResult.Heading}
              </div>
              <div className={`fs-14 w-400 mt-1 ${styles.indigoColor}`}>
                {filterNoSearchResult.SubHeading}
              </div> */}
            </div>
          )}
        
          <WhiteWrapper>
            <Button
                className="fs-15 text-white fw-600 pointer"
                onClick={()=>{
                    setCategoryInput(selectedIds);
                    modalClosehandler();
                }}
                disabled={selectedIds.length === 0}
                >
                {"Add items"}
            </Button>
            
          </WhiteWrapper>
        </div>
      </Modal>
    )
}