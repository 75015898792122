export default {
  z50: "#F6FCFC",
  z100: "#E5F3F3",
  z200: "#B6DEE0",
  z300: "#7ACDCD",
  z400: "#12A2AB",
  z500: "#119199",
  z600: "#0A757C",
  z700: "#0A6166",
  z800: "#034F50",
  z900: "#003331"
};
