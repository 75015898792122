import { isMobile } from "helpers/helper";
import React from "react";

const Cards = ({ data }) => {
  const isMweb = isMobile(992);
  return (
    <div style={{ marginBottom: isMweb ? "40px" : "120px" }}>
      <div className="d-flex flex-column align-items-center">
        <span
          style={{
            fontSize: data?.title?.font_size + "px",
            fontWeight: data?.title?.font_weight,
            color: data?.title?.text_color,
            marginBottom: isMweb ? "20px" : "40px",
            textAlign: isMweb ? "left" : "center",
            width: "100%",
          }}
        >
          {" "}
          {data?.title?.text}
        </span>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: isMweb ? "12px" : "20px",
          justifyContent: "center",
          gridAutoRows: isMweb ? "" : "1fr"
        }}
      >
        {data?.features?.map((feature) => (
          <div
            className={`d-flex flex-column ${
              isMweb ? "align-items-start" : "align-items-center"
            }`}
            style={{
              background: "#F8F9FC",
              padding: `${isMweb ? "16px 12px" : "38px 32px"}`,
              borderRadius: isMweb ? "12px" : "20px",
              width: "100%", 
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
              height: isMweb ? "" : "100%",
              textAlign: isMweb ? "left" :"center",
            }}
          >
            <img
              src={feature?.media?.media_url}
              height={feature?.media?.height}
              width={feature?.media?.width}
              style={{marginBottom: isMweb ? "16px" : "28px"}}
            />
            <div
              style={{
                color: feature?.title?.text_color,
                fontWeight: feature?.title?.font_weight,
                fontSize: feature?.title?.font_size + "px",
                width:"100%",
                lineHeight: "1.3",
                marginBottom: "12px",
              }}
            >
              {feature?.title?.text}
            </div>
            <div
              style={{
                color: feature?.description?.text_color,
                fontWeight: feature?.description?.font_weight,
                fontSize: feature?.description?.font_size + "px",
                lineHeight: "1.3"
              }}
            >
              {" "}
              {feature?.description?.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
