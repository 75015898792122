import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { savePDF, PDFExport } from "@progress/kendo-react-pdf";
import { SeparatorLine } from "components/core/Common/styles.common";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import DOMPurify from "dompurify";

const ViewPDF = (props) => {
  const containerRef = useRef();
  const [numPages, setNumPages] = useState(null);
  const pdfWidth = window.innerWidth;

  useEffect(() => {
    drawDOM(containerRef.current, {
      paperSize: "A4",
      keepTogether: ".keep-together",
      margin: 20,
    })
      .then((group) => exportPDF(group))
      .then((dataURI) => {
        props.setPdfBlob(dataURI);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [containerRef.current]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  return (
    <React.Fragment>
      <div className="hide-pdf-container default-invoice">
        <div ref={containerRef}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.htmlWithoutPDF) }}></div>{" "}
          {/* sanitizeHtml(htmlWithoutPDF) DISCUSS */}
        </div>
      </div>

      {!!props.pdfBlob && (
        <div style={{ overflow: "scroll", height: "78vh", scale: "0.9" }}>
          <Document file={props.pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={pdfWidth}
                />
                <SeparatorLine />
              </>
            ))}
            {/* <Page pageNumber={1}
                                renderTextLayer={false} 
                                renderAnnotationLayer={false}/> */}
          </Document>
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewPDF;
