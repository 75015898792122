export const DataURItoBlob = (dataURI) => {
    const [mime, data] = dataURI.split(',');
    const byteString = atob(data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
}
function getWindow() {
    if (typeof window !== 'undefined') {
        return window;
    }
    return undefined;
}
export function isMobile(mobileWidth) {
    if (getWindow() && window.innerWidth <= (mobileWidth ?? 500)) {
        return true;
    }
    return false;
}