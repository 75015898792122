import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "sushiweb/Spinner/Spinner";
import {
  GRN_STATUS_COMPLETED, ORDER_STATUS_DISPATCHED,
  PAYMENT_STATUS_PENDING, PO_ORDER_TAG,
  PO_STATUS_APPROVED, SELLER_ACTION_STATUS_COMPLETED, SELLER_ACTION_STATUS_PENDING,
  SELLER_STATUS_NON_APPROVED,
  STATUS_BEGIN,
  STATUS_DONE,
  STATUS_FAIL,
} from "utils/constants/others";
import { strings } from "utils/constants/strings";
import Header from "../Header/Header";
import SubHeader from "../Header/Header";
import { Loader } from "../Common/Loader";
import { AllPoButton, Box, Circle, LargeBox, Wrapper, CircleDDPo } from "./styles.homepage";
import useVendorStore from "./useVendorStore";
import {SeparatorLine, WhiteWrapper,SushiModal,FlexColumn,Button} from "../Common/styles.common";
import moment from "moment/moment";
import ShowToast from "../Common/Toast";

const HomePage = () => {
  const outletName = window.localStorage.getItem("outlet_name");
  const outletId = window.localStorage.getItem("outlet_id");
  const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
  const getPurchaseOrderStatus = useVendorStore(
    (state) => state.getPurchaseOrderStatus
  );
  const getPoGrnMappings = useVendorStore((state) => state.getPoGrnMappings);
  const getPoGrnMappingStatus = useVendorStore(
    (state) => state.getPoGrnMappingStatus
  );
  const getHomePagePopUp= useVendorStore((state) => state.getHomePagePopUp);
  const poCount = useVendorStore((state) => state.poCount);
  const grnCount = useVendorStore((state) => state.grnCount);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [po, setPo] = useState([]);
  const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
  const [items, setItems] = useState("");
  const [pendingInvoiceCount, setPendingInvoiceCount] = useState(0);
  const [pendingDeliveryCount, setPendingDeliveryCount] = useState(0);
  const sellerDetails = useVendorStore((state) => state.sellerDetails);
  const homePagePopUp = useVendorStore((state) => state.homePagePopUp);
  const updateHomePagePopUp = useVendorStore((state) => state.updateHomePagePopUp);
  const getSellerDetails = useVendorStore((state) => state.getSellerDetails);
  const [showSubHeader,setShowSubHeader] = useState(false);
  const [currentSellerDetail,setCurrentSellerDetail] = useState({});
  const [allSellersDetails,setAllSellersDetails] = useState([]);
  const [showButtonLoader,setShowButtonLoader] = useState(false);
  const [checkForPopUp,setCheckForPopUp] = useState(false);
  const [showTncModal, setShowTncModal] = useState(false);
  const [showToast,setShowToast] = useState(false);
  const [toastMsg,setToastMsg] = useState("");
  const updateHomePagePopUpStatus = useVendorStore((state) => state.updateHomePagePopUpStatus);
  const [errorMsg, setErrorMsg] = useState("");
  const fetchAppConfigs = useVendorStore((state) => state.fetchAppConfigs);

  useEffect(() => {

    getPurchaseOrders({
      vendor_id: outletId,
      po_status: PO_STATUS_APPROVED,
      seller_status: SELLER_STATUS_NON_APPROVED,
      fetch_po_with_no_grns:true,
    });
    getPoGrnMappings({
      grn_status: GRN_STATUS_COMPLETED,
      seller_status: SELLER_STATUS_NON_APPROVED,
      outlet_id: outletId,
      payment_status: PAYMENT_STATUS_PENDING
    },true);
    getPurchaseOrders({
      vendor_id: outletId,
      is_item_required: true,
      order_tag: PO_ORDER_TAG,
      fetch_invoice: true,
      fetch_po_with_no_seller_invoice: true
    }, true, storePurchaseOrderData, "INVOICE");
    getPurchaseOrders({
      vendor_id: outletId,
      is_item_required: true,
      order_tag: PO_ORDER_TAG,
      fetch_invoice: true,
      fetch_po_with_no_seller_invoice: false,
      order_status: `${ORDER_STATUS_DISPATCHED},${SELLER_ACTION_STATUS_COMPLETED}`,
      seller_action_status: SELLER_ACTION_STATUS_PENDING,
      is_delivery_details_required: true
    }, true, storePurchaseOrderData, "PENDING");

    getSellerDetails({
      phone_number: window.localStorage.getItem("phone_number"),
      check_for_dn_tncs: true,
    });
  }, []);

useEffect(() => {
if (homePagePopUp?.heading?.length>0) {
  setShowTncModal(true)
}
},[homePagePopUp])

useEffect(() => {
  if (updateHomePagePopUpStatus === STATUS_DONE) {
    setShowButtonLoader(false)
    setShowToast(true)
  }
  if (updateHomePagePopUpStatus === STATUS_FAIL) {
    setShowButtonLoader(false)
    setToastMsg("Error in updating the status. Please try again later.")
    setShowToast(true)
  }
},[updateHomePagePopUpStatus])

useEffect(() => {
  if (checkForPopUp) {
    getHomePagePopUp({
      seller_outlet_id: outletId
    })
  }
},[checkForPopUp])

  useEffect(() => {
      if (sellerDetails?.seller_outlet_details?.length > 0) {
        setShowSubHeader(true);
        let allSellerOutletDetails = sellerDetails?.seller_outlet_details
        const index = allSellerOutletDetails.findIndex(item => item.outlet_id == outletId); // Find the index of current outlet

        setCheckForPopUp(sellerDetails?.seller_outlet_details[index]?.show_dn_tnc_popup)

        setCurrentSellerDetail(sellerDetails?.seller_outlet_details[index])
        let currentOutlet = sellerDetails?.seller_outlet_details[index]
        if (index !== -1 && index !== 0) {
          const itemToMove = allSellerOutletDetails.splice(index, 1); // Remove the item from its current position
          const updatedSellerList = [...itemToMove, ...allSellerOutletDetails]; // Concatenate the item to the beginning of the array
          setAllSellersDetails(updatedSellerList)
        } else {
          setAllSellersDetails(allSellerOutletDetails)
        }
        if (!isNotFarmerOutlet(currentOutlet)) {
          window.localStorage.setItem("seller-type", strings.farmerOutlet)
        } else {
          window.localStorage.removeItem("seller-type")
        }
        
        if (!window.localStorage.getItem('referrer_platform')?.length) {
          fetchAppConfigs({
            app_mode: strings.appModeSeller,
          })
        }
      }
  },[sellerDetails])

  function storePurchaseOrderData(data, source) {
    if (source === "INVOICE") {
      setPendingInvoiceCount(data.order_count);
    } else if (source === "PENDING") {
      setPendingDeliveryCount(data.order_count);
      setPo(data.purchase_orders.slice(0, Math.min(data.order_count, 3)))
      setItems(data.purchase_orders.purchase_order_items);
    }
  }

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_BEGIN || getPoGrnMappingStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
    if (getPurchaseOrderStatus === STATUS_DONE && getPoGrnMappingStatus === STATUS_DONE) {
      setShowLoader(false);
    }
  }, [getPurchaseOrderStatus, getPoGrnMappingStatus]);

  const checkPendingPOs = () => {
    navigate("po/pending");
  };

  const checkPendingGRNs = () => {
    navigate("grn/pending");
  };

  const checkAllPOs = () => {
    navigate("po/all");
  };

  const checkAllDDPOs = () => {
    navigate("ddPo/all");
  };

  const toggleLogoutModal = () => {
    setOpenLogoutModal(!openLogoutModal);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("outlet_id");
    window.localStorage.removeItem("outlet_name");
    window.localStorage.removeItem("seller-type")
    window.localStorage.removeItem("phone_number");
    if (window && window.logoutChannelMessageHandler) {
      window.logoutChannelMessageHandler.postMessage("true");
    }
    window.location.reload();
  };

  const naviagetToDelieryPage = (poId, source) => {
    navigate(`/ddPo/details/${source}/${poId}`)
  }

  const getDateMonthFromString = (timestamp, value) => {
    const monthInt = moment(new Date(timestamp))
        .format("MM");
    const day = moment(new Date(timestamp))
        .format("MM").toString();
    const monthStr = moment.months(parseInt(monthInt))
    const date = new Date(timestamp);
    if (value == "day") {
      return day
    } else if (value == "month") {
      return monthStr
    }
  }
  const isNotFarmerOutlet= (currentSellerDetail) => {    
    if (currentSellerDetail?.outlet_type == strings.farmerOutlet) {
          return false
    }
    return true
  }

  function calculateTonnage (items) {
    let total = 0;
    for(const item of items){
      total=total+ item.quantity_ordered* item.weight_per_packet
    }
    let value = total > 999 ? (total/1000).toFixed(2) : total.toFixed(2)
    return value
  }

  const handleConfirmGrnCTA=(grnId) =>{
  {
    setShowButtonLoader(true)
    updateHomePagePopUp(homePagePopUp?.cta?.Action?.content?.Body
    )
  }
    setShowTncModal(false)
}
  return (
    <React.Fragment>
      <Header 
      showLogout={true} 
      handleLogout={handleLogout} 
      showSubHeader = {showSubHeader} 
      sellerDetail = {currentSellerDetail} 
      allSellerDetails = {allSellersDetails}
      />
     <SushiModal visible={showTncModal} isCentered>
        <FlexColumn className="align-items-center p-5">
          <img src="/logos/tick_logo.svg" height="80px" width="80px" />
          <span className="fs-21 fw-700 my-4 text-align-center">
            {
                homePagePopUp,homePagePopUp?.heading
              }
            </span>      
              <>
              <span 
  className="fs-14 fw-400 text-gray-cool mt-1 my-3" 
  dangerouslySetInnerHTML={{ __html: homePagePopUp?.cta?.Text }}>
</span>
              </>
          <Button
            className="fs-13 text-white fw-600 mb-3"
            onClick={() => handleConfirmGrnCTA()}
            disabled={false}
          >
            {showButtonLoader? 
            <Spinner color={"white"} />
            :
            homePagePopUp,homePagePopUp?.footer_text}
          </Button>
        </FlexColumn>
      </SushiModal>
      {showLoader ? (
        <Loader />
      ) : (
          <div className="header-content">
            <div className="d-flex flex-column justify-content-center">
              <Box className="fs-26 fw-700 mt-3 mb-3">{outletName}</Box>
              {((pendingInvoiceCount == 0 && pendingDeliveryCount == 0) || currentSellerDetail?.should_show_all_dd_and_non_dd_po_grns) ?
                <div>
                  <Box className="fs-14 fw-400 mt-3 mb-3 text-gray-cool">
                    {poCount
                      ? grnCount
                        ? "You have POs and GRNs pending approval"
                        : "You have POs pending approval"
                      : grnCount
                      ? "You have GRNs pending confirmation"
                      : "You are all caught up"}
                  </Box>
                    <div className="d-flex justify-content-between mt-5 mb-4">
                      {poCount ? (
                          <LargeBox onClick={checkPendingPOs}>
                            <img src="./logos/po_logo.svg" height="80px" width="80px" />
                            <Circle>
                        <span
                            className={
                              poCount > 99
                                  ? "fs-10 fw-600 text-white align-self-center"
                                  : "fs-14 fw-600 text-white align-self-center"
                            }
                        >
                          {poCount}
                        </span>
                            </Circle>

                            <Box className="fs-16 fw-600">{strings.pendingPoString}</Box>
                          </LargeBox>
                      ) : (
                          <LargeBox className="greyScale">
                            <img
                                src="./logos/po_logo.svg"
                                height="80px"
                                width="80px"
                                style={{ marginBottom: "22px" }}
                            />
                            <Box className="fs-16 fw-600">
                              {strings.noPendingPoString}
                            </Box>
                          </LargeBox>
                      )}
                      {grnCount ? (
                          <LargeBox onClick={checkPendingGRNs}>
                            <img src="./logos/grn_logo.svg" height="80px" width="80px" />
                            <Circle>
                        <span
                            className={
                              grnCount > 99
                                  ? "fs-10 fw-600 text-white align-self-center"
                                  : "fs-14 fw-600 text-white align-self-center"
                            }
                        >
                          {grnCount}
                        </span>
                            </Circle>
                            <Box className="fs-16 fw-600">{strings.pendingGrnString}</Box>
                          </LargeBox>
                      ) : (
                          <LargeBox className="greyScale">
                            <img
                                src="./logos/grn_logo.svg"
                                height="80px"
                                width="80px"
                                style={{ marginBottom: "22px" }}
                            />
                            <Box className="fs-16 fw-600">
                              {strings.noPendingGrnString}
                            </Box>
                          </LargeBox>
                      )}
                    </div>
                  </div>
                  : <></>
              }

        {
             (isNotFarmerOutlet(currentSellerDetail) || currentSellerDetail?.should_show_all_dd_and_non_dd_po_grns) &&  <AllPoButton>
                <div className="d-flex" onClick={checkAllDDPOs}>
                  <div className="circle ml-5">
                    <img src="./logos/truck.svg" height="35px" width="35px" className="mt-2 ml-2" />
                  </div>
                  <CircleDDPo>
                      <span
                          className={
                            pendingInvoiceCount > 99
                                ? "fs-10 fw-600 text-white align-self-center"
                                : "fs-14 fw-600 text-white align-self-center"
                          }
                      >
                        {pendingInvoiceCount}
                      </span>
                  </CircleDDPo>
                  <span
                      className="fs-16 w-600 fw-600 ml-2 mt-4"
                  >
                    {strings.ddPOs}
                    </span>
                  <div>
                    <img
                        src={"/logos/front_logo.svg"}
                        className="ml-5 mt-4"
                    />
                  </div>
                </div>
              </AllPoButton>
             }

             {
            (isNotFarmerOutlet(currentSellerDetail) || currentSellerDetail?.should_show_all_dd_and_non_dd_po_grns) && <WhiteWrapper className="invoice-form">
                <div className="d-flex align-items-center justify-content-between">
                  <span
                      className="fs-16 w-600 fw-600 text-align-r"
                  > Pending deliveries </span>
                  <span
                      className="fs-16 w-600 fw-600 text-red-500"
                      onClick={checkAllDDPOs}
                  > See all </span>
                </div>
                <SeparatorLine />
                {po?.length > 0 ? po.map((p, index) => {
                  return (
                      <div key={index}>
                        <div className="d-flex align-items-center justify-content-between" onClick={() => naviagetToDelieryPage(p.id, "pod")}>
                          <div className="circle-small d-flex flex-column">
                            <span className="fs-9 fw-600 align-self-center mt-3">{getDateMonthFromString(p.po_consideration_date, "day")}</span>
                            <span className="fs-9 fw-600 align-self-center">{getDateMonthFromString(p.po_consideration_date, "month")}</span>
                          </div>
                          <div className="d-flex flex-column mr-4 ml-4">
                            <span className="fs-15 fw-700">{p.purchase_order_number}</span>
                            <span className="fs-12 fw-400 text-gray-cool mt-1">
                        {p.purchase_order_items.length} product{p.purchase_order_items.length > 1 ? "s" : ""}
                              {" "}•{" "}{calculateTonnage(p.purchase_order_items)} {(p.purchase_order_items)>999 ? "tonne": "kg"}
                            </span>
                              </div>
                              <span>
                              <img
                                  src={"/logos/front_logo.svg"}
                                  className="ml-5"
                              />
                          </span>
                        </div>
                        { index != po.length -1 ? <SeparatorLine /> : <></> }
                      </div>
                  )
                }) : <></>}
              </WhiteWrapper>
            }
              {((pendingInvoiceCount == 0 && pendingDeliveryCount == 0) || currentSellerDetail?.should_show_all_dd_and_non_dd_po_grns) ?
                <AllPoButton className="mt-3">
                  <span
                    className="fs-16 w-600 fw-600 text-red-500"
                    onClick={checkAllPOs}
                  >
                    {strings.seeAllPoString}
                  </span>
                </AllPoButton>
              :
                  <></>
              }
            </div>
          </div>
      )}
      {showToast?
                <ShowToast
                errorMsg={errorMsg.length > 0 ? "errorMsg" : toastMsg}
                toastMsg={toastMsg}
                />
                :
                <></>
            }
    </React.Fragment>
  );
};

export default HomePage;
