
const checkboxSelected = {
  disabled_solid: () => ({
    borderColor: "CFCFCF",
    background: "CFCFCF",
    tickColor: "#FFFFFF"
  }),
  disabled: () => ({
    borderColor: "CFCFCF",
    tickColor: "CFCFCF",
    background: "#FFFFFF"
  }),
  solid: color => ({
    borderColor: color ||"#119199",
    background: color || "#119199",
    tickColor: "#FFFFFF"
  }),
  default: color => ({
    borderColor: color || "#119199",
    tickColor: color || "#119199",
    background: "#FFFFFF"
  })
};

const checkbox = {
  disabled: {
    borderColor: "CFCFCF",
    hoverBorderColor: "CFCFCF"
  },
  default: color => ({
    borderColor: "CFCFCF",
    hoverBorderColor: color || "#119199"
  })
};

const getClickedAttr = (cbType, color) => checkboxSelected[cbType](color);

const getAttr = (disabled, color) =>
  disabled ? checkbox.disabled : checkbox.default(color);

const getClickedAttributes = key => ({ cbType, color }) =>
  getClickedAttr(cbType, color)[key];

const getAttributes = key => ({ disabled, color }) =>
  getAttr(disabled, color)[key];

const getType = (disabled, solid) => {
  const key = [];
  if (disabled) {
    key.push("disabled");
  }
  if (solid) {
    key.push("solid");
  }

  return key.length ? key.join("_") : "default";
};

export { getAttributes, getClickedAttributes, getType };
