import React, { useState } from "react";
import "./Faq.css";
import Accordion from "./Accordian";
import styles from "./landingPageStyles.module.scss";
import { isMobile } from "helpers/helper";

const queAns = [
  {
    question: "What is Frontend Mentor, and how will it help me?",
    answer:
      "Frontend Mentor offers real coding challenges to help developers improve their frontend coding    skills with projects in HTML, CSS, and JavaScript. It's suitable for all levels and ideal for building a portfolio.",
  },
  {
    question: "que 2",
    answer:
      "soniyaaaaa",
  },
];

const Faqs = ({data}) => {
  const [currentIcon, setIcon] = useState("+");
  const [openQuestion, setOpenQuestion] = useState(null);
  const handleOnClick = (question) => {
    setIcon(currentIcon === "+" ? "-" : "+");
    setOpenQuestion(openQuestion === question ? null : question);
  };
  const isMweb = isMobile(992);

  return (
    <div>
      <div
        style ={{
          fontSize: isMweb ? "24px" : "40px",
          fontWeight: "700",
          color: "#1C1C1C",
          marginBottom: isMweb ? "20px" : "30px",
          textAlign: isMweb ? "left" : "center",
        }}
      >
        Frequently asked questions
      </div>
    <div style={{ marginBottom: "20rem", cursor: "pointer" }}>
    {data?.map((que) => (
      <div className="faq-box" key={que.question} onClick={() => handleOnClick(que.question)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          
          <div style={{fontSize:isMweb ? "16px":"24px", fontWeight:"500", alignContent:"center", lineHeight: "1.3"}}>{que?.question}</div>
          <div>{<img src="/logos/chevron-left.svg" height={isMweb ? "24px" : "32px"} width={isMweb ? "24px" : "33px"} className={openQuestion === que.question ? styles.upImage : styles.downImage}/>}</div>
          
        </div>
        <div className={`answer ${openQuestion === que.question ? "" : "hidden"} ${isMweb ? "fs-14" : "fs-20"}`} style={{ lineHeight: "1.3", whiteSpace: "pre-line" }}>
        {que?.answer.split("hpsourcing@zomato.com").map((part, index) =>
          index === 0 ? (
            part
          ) : (
            <>
              <a href="mailto:hpsourcing@zomato.com">hpsourcing@zomato.com</a>
              {part}
            </>
          )
        )}
        </div>
      </div>
    ))}
  </div>
  </div>
  );
};

export default Faqs;
