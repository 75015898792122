import { isMobile } from "helpers/helper";
import React from "react";

const Partner = ({ data }) => {
  const isMweb = isMobile(992);
  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ marginBottom: isMweb ? "40px" : "100px" }}
    >
      <span
        style={{
          marginBottom: "16px",
          fontSize: data?.title?.font_size + "px",
          fontWeight: data?.title?.font_weight,
          color: data?.title?.text_color,
          width: "100%",
          textAlign: isMweb ? "left" : "center",
        }}
      >
        {" "}
        {data?.title?.text}
      </span>
      <img src={data?.media?.media_url} width={"100%"} alt="partners" fetchPriority="high"/>
    </div>
  );
};

export default Partner;
