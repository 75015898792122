import React, { useEffect, useState } from "react";
import useAuthStore from "../Auth/useAuthStore";
import Cards from "./Cards";
import { isMobile } from "helpers/helper";
import Partner from "./Partner";
import Faqs from "./Faqs";

import { Button, SeparatorLine, SushiModal } from "../Common/styles.common";
import RegristrationForm from "./RegristrationForm";
import ShowToast from "../Common/Toast";
import Spinner from "sushiweb/Spinner/Spinner";
import useCommonStore from "../Common/useCommonStore";

const LandingPage = () => {
  const { sellerHubHomePageData, getSellerCategories} = useAuthStore();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState({
    message: "",
    type: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const homePageData = useAuthStore((state) => state.sellerHubData);
  const sellerCategories = useAuthStore((state) => state.sellerCategories);
  const sellerHubHomePageDataStatus = useAuthStore((state) => state.loginDetails.sellerHubHomePageData);
  const isMweb = isMobile(992);
  const onBoardingFormData = useAuthStore((state) => state.onBoardingFormData);
  const urlUploadData = useCommonStore((state) => state.urlUploadData);

  useEffect(() => {
    sellerHubHomePageData();
  }, []);

  useEffect(() => {
    if (onBoardingFormData?.type === "error") {
      setShowToast({
        message: onBoardingFormData?.message,
        type: onBoardingFormData?.type,
      });
    }
  }, [onBoardingFormData]);

  const onToggleContactModal = () => {
    setShowContactUsModal((prev) => !prev);
    getSellerCategories();
  };

  useEffect(() => {
    if (sellerHubHomePageDataStatus.begin === true) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [sellerHubHomePageDataStatus]);

  useEffect(() => {
    if (urlUploadData) {
      setShowToast({
        message: urlUploadData?.message,
        type: urlUploadData?.type,
      });
    }
  }, [urlUploadData]);

  return (
    <React.Fragment>
      {showLoader ? (
        <div className="d-flex wd-100 mt-6 align-items-center justify-content-center">
          <Spinner size="large" color="#EF4F5F" />
        </div>
      ) : (
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height:"100vh"
        }}
      >
        <div
          style={{
            width: '100%',
            height: isMweb ? '578px' : '612px',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
            marginBottom: isMweb ? "38px" : "62px",
            flexShrink: 0,
          }}
        >
          <img
            src={homePageData?.hero_banners?.[0]?.media_url?.media_url}
            alt="homepage banner"
            style={{
              height: '100%',
              width: "100%",
              cursor: "pointer",
              objectFit: 'cover',
              objectPosition: 'center',
              borderBottomLeftRadius: isMweb ? '16px' : '30px',
              borderBottomRightRadius: isMweb ? '16px' : '30px'
            }}
            onClick={onToggleContactModal}
            fetchPriority="high"
          />
        </div>
        <div
          style={{
            marginLeft: `${isMweb ? "16px" : "230px"}`,
            marginRight: `${isMweb ? "16px" : "230px"}`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Partner data={homePageData?.seller_partner} />
          <Cards data={homePageData?.seller_hub_feature} />
          <Faqs data={homePageData?.faqs} />
          {showContactUsModal && (
            <RegristrationForm
              showContactUsModal={showContactUsModal}
              onToggleContactModal={onToggleContactModal}
              categories={sellerCategories}
              setShowSuccessModal={setShowSuccessModal}
            />
          )}
          {showToast?.message?.length > 0 && (
            <ShowToast
              show={true}
              toastMsg={showToast.message}
              type={showToast.type}
            />
          )}
        </div>
      </div>
      )}
      {showSuccessModal ?
        <SushiModal visible={true} isCentered>
          <div className="d-flex flex-column align-items-center" style={{padding: isMweb ? "16px" : "24px"}}>
            <img src="/logos/tick_logo.svg" height="80px" width="80px" />
            <span 
              className="my-4" 
              style={{  
                      whiteSpace: "pre-line", 
                      fontSize:isMweb ? "18px" : "24px",
                      fontWeight:"700",
                      textAlign: "center",
                      lineHeight: "1.3"
              }}
            >
              {onBoardingFormData?.message?.replace(/!/g, "!\n")}
            </span>
            <SeparatorLine mt={isMweb? "0px": "10px"}/>
            <span
              className="fw-500 text-red-500 pointer"
              style={{fontSize:isMweb ? "14px" : "16px"}}
              onClick={() => setShowSuccessModal(false)}
            >
              {" "}
              Okay
            </span>
          </div>
        </SushiModal>
         :
        <></>
      }
    </React.Fragment>
  );
};

export default LandingPage;
