const button = {
  large: { height: "48px" },
  default: { height: "44px" },
  compressed: { height: "36px" },
  small: { height: "32px" }
};

const input = {
  fontSize: "18px",
  width: "300px",
  height: "48px"
};

const inputPhFocused = {
  top: "-5px",
  left: "10px",
  fontSize: "11px",
  padding: "0 5px"
};

const inputNoLabel = {
  display: "none"
};

const inputPhDefault = {
  top: "13px",
  left: "14px",
  fontSize: "18px",
  padding: "0"
};

const inputPhLeftIcon = {
  top: "13px",
  left: "37px",
  fontSize: "18px",
  padding: "0"
};

const inputPadding = {
  vPadding: 1.3,
  hzPadding: 1.2
};

const imgUploaderError = {
  tagFontSize: 20,
  tagLineHeight: 28,
  imgHeight: 34
};

const imgUploaderDefault = {
  tagFontSize: 15,
  tagLineHeight: 20,
  imgHeight: 43
};

const radioViewDisplay = {
  horizontal: {
    display: "flex"
  },
  default: {
    display: "block"
  }
};

const addItemButton = {
  huge: { height: "4.8rem" },
  large: { height: "4.4rem" },
  compressed: { height: "3.6rem" },
  default: { height: "3.2rem" }
};

export {
  button,
  input,
  inputPhFocused,
  inputPhDefault,
  inputNoLabel,
  inputPadding,
  radioViewDisplay,
  imgUploaderError,
  imgUploaderDefault,
  inputPhLeftIcon,
  addItemButton
};
