import React, { useState, useEffect } from "react";
import styles from "./Login.module.scss";
import Button from "sushiweb/Button";
import Input from "sushiweb/InputTypes/Input";
import { PROFILE_INPUT_BORDER } from "../../../utils/constants/others";
import Header from "../Header/Header";
import Spinner from "sushiweb/Spinner";
import { strings } from "utils/constants/strings";
import LandingPage from "../LandingPage/LandingPage";
import Modal from "sushiweb/Modal";

const UserInput = (props) => {
  const [isDisable, setIsDisable] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  useEffect(() => {
    if (props.userInput.length > 0) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [props.userInput]);

  useEffect(() => {
    if (props.loginDetails.sendOtp.begin === true) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }

    if (props.loginDetails.sendOtp.done === true) {
      props.setIsOtpSent(true);
    } else {
      props.setIsOtpSent(false);
    }

    if (props.loginDetails.sendOtp.fail === true) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [props.loginDetails.sendOtp]);

  const errorMessage = () => {
    if (props.isValidationsFailed) {
      return strings.validationErrorMsg;
    } else if (showError) {
      return props.error?.message?.split("UserMessage : ")[1];
    } else {
      return "";
    }
  };
  const toggleLoginModal = () => {
    setShowLoginModal((prev) => !prev);
    if (!showLoginModal) {
      props.setIsOtpSent(false);
      props.setUserInput("");
    }
  };

  return (
    <React.Fragment>
      <Header toggleLoginModal={toggleLoginModal} />
      {showLoginModal && (
        <Modal
          visible={true}
          isCentered={true}
          // title="Let's get started"
          onClose={toggleLoginModal}
          // type="medium"
          className={styles.modalRadius}

        >
            <div className={`d-flex flex-column align-items-center p-4 ${styles.modalContent}`}>
              <div className={`d-flex flex-column justify-content-center wd-90`}>
                <img
                  src={props.homePageData?.login_phone_number_image?.media_url}
                  height="160px"
                  width="160px"
                />

                <div className="fs-30 fw-600 mt-4">Enter phone number or email</div>
                <div className="fs-16 fw-400 text-gray-600 mt-3 mb-4">OTP will be sent to this number/email for verification</div>

                <div className="relative">
            
                  <Input
                    autoFocus
                    value={props.userInput}
                    onChange={(value) => props.setUserInput(value)}
                    className={`${styles.genericInput} ${styles.mobileNumberInput}`}
                    placeholder={"Email/Phone Number"}
                    errorMsg={errorMessage()}
                  />
                </div>

                {/* <Input
                  type="text"
                  label="Email/Phone Number"
                  borderColor={PROFILE_INPUT_BORDER}
                  value={props.userInput}
                  errorMsg={errorMessage()}
                  className={styles.InputPhoneNumberStyle}
                  onChange={(value) => props.setUserInput(value)}
                /> */}

                <Button
                  btnColor="red"
                  size="compressed"
                  className={"sendOtpButton"}
                  onClick={props.handleUserInputSubmit}
                  disabled={isDisable}
                >
                  <span className="fs-15 fw-500">Send OTP</span>
                </Button>
              </div>
            </div>
        </Modal>
      )}
      <LandingPage />
      {showLoader ? (
        <div className="d-flex wd-100 mt-6 align-items-center justify-content-center">
          <Spinner size="large" color="#EF4F5F" />
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export default UserInput;
