import { create } from "zustand";
import { api } from "network/network";
import { APIendpoints, DefaultParams } from "utils/constants/APIendpoints";
import { devtools } from "zustand/middleware";
import { API_HOST_WMS } from "config/api.config";
import { makeEncodedQueryParams } from "operations/operations";
import _get from "lodash-es/get";
import { SELLER_STATUS_APPROVED, STATUS_BEGIN, STATUS_DONE, STATUS_FAIL } from "utils/constants/others";

let useCommonStore = create(
  devtools((set, get) => ({
    uploadDocumentStatus: "",
    uploadedDocumentUrl: "",
    error: "",

    uploadDocument: async (formData,id,successCallback) => {
      set({ uploadDocumentStatus: STATUS_BEGIN });
      api
        .post(API_HOST_WMS, APIendpoints.uploadDocument, formData)
        .then((response)=>{
            set({
                uploadedDocumentUrl: _get(
                  response,
                  ["data", "response", "response"],
                  {}
                ),
            });
            set({ uploadDocumentStatus: STATUS_DONE });

            successCallback(id, get().uploadedDocumentUrl)
            
        })
        .catch((error) => {
          set({
            error: _get(error, ["response", "data", "error"], {}),
          });
          set({ uploadDocumentStatus: STATUS_FAIL });
        });
    },

    resetUploadedDocument: () => {
      set({ uploadedDocumentUrl: null });
    },

    uploadLogoutStateDocument: async (formData,successCallback) => {
      set({ uploadDocumentStatus: STATUS_BEGIN });
      api
        .post(API_HOST_WMS, APIendpoints.uploadLogOutStateDocument, formData)
        .then((response)=>{
            set({
                uploadedDocumentUrl: _get(
                  response,
                  ["data", "response", "response"],
                  {}
                ),
            });
            set({ uploadDocumentStatus: STATUS_DONE });
            successCallback()
            // successCallback(id, get().uploadedDocumentUrl)
            
        })
        .catch((error) => {
          set({
            urlUploadData: {
              message: error?.response?.data?.error?.message,
              type: 'error'
            }
          });
          set({
            error: _get(error, ["response", "data", "error"], {}),
          });
          set({ uploadDocumentStatus: STATUS_FAIL });
        });
    },

    uploadDocs: async (formData,success_callback) => {
      set({ uploadDocumentStatus: STATUS_BEGIN });
      api
          .post(API_HOST_WMS, APIendpoints.uploadDocument, formData)
          .then((response)=>{
              set({
                  uploadedDocumentUrl: _get(
                      response,
                      ["data", "response", "response"],
                      {}
                  ),
              });
              set({ uploadDocumentStatus: STATUS_DONE });
              if (typeof(success_callback)==="function"){
                success_callback(_get(
                    response,
                    ["data", "response", "response"],
                    {}
                ));
              }
          })
          .catch((error) => {
              set({
                  error: _get(error, ["response", "data", "error"], {}),
              });
              set({ uploadDocumentStatus: STATUS_FAIL });
          });
    },
     
      uploadOrderBillDocument: async (formData, updatePurchaseOrder, param, poId) => {
          set({ uploadDocumentStatus: STATUS_BEGIN });
          api
              .post(API_HOST_WMS, APIendpoints.uploadDocument, formData)
              .then((response)=>{
                  set({
                      uploadedDocumentUrl: _get(
                          response,
                          ["data", "response", "response"],
                          {}
                      ),
                  });
                  set({ uploadDocumentStatus: STATUS_DONE });

                  updatePurchaseOrder({
                      id: poId,
                      supplier_bill_number: param.invoice_number,
                      total_supplier_bill_amount: parseFloat(param.invoice_amount),
                      supplier_bill_image: get().uploadedDocumentUrl,
                      supplier_bill_date: param.invoice_date
                  })

              })
              .catch((error) => {
                  set({
                      error: _get(error, ["response", "data", "error"], {}),
                  });
                  set({ uploadDocumentStatus: STATUS_FAIL });
              });
      },

      uploadFileToS3: async (file, preSignedUrl, success_callback, error_callback) => { 
        try {
            const response = await fetch(preSignedUrl, {
              method: "PUT",
              body: file,
              headers: {
                "Content-Type": file?.type,
              },
            });
        
            if (response.ok) {
            success_callback()
            } else {
                error_callback();
            }
          } catch (err) {
            error_callback();
          }
        }
  }))
);

export default useCommonStore;